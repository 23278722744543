<template>
    <div class="login">
        <h1 class="login__header">Welcome to Perseus SAT</h1>
        <mf-button class="form__item form__button" color="blue" @click="redirectToLogin">
            Login
        </mf-button>
        <mf-lang-select class="login__language"></mf-lang-select>
    </div>
</template>

<script>
    import MfLangSelect from '@/components/MfLangSelect';

    export default {
        components: { MfLangSelect },
        data() {
            return {
                form: {
                    username: null,
                    password: null,
                },
            };
        },
        watch: {
            '$route.hash': {
                async handler(val) {
                    const searchParams = new URLSearchParams(val.replace('#', ''));
                    if (searchParams.get('access_token')) {
                        const response = await this.$store.dispatch('login', {
                            token: searchParams.get('access_token'),
                            tokenType: searchParams.get('token_type'),
                            expiresIn: searchParams.get('expires_in'),
                        });
                        response && (await this.$router.push({ name: 'portcall' }));
                    }
                },
                immediate: true,
            },
        },
        methods: {
            redirectToLogin() {
                const {
                    VUE_APP_AUTH_URL: authUrl,
                    VUE_APP_CLIENT_ID: clientId,
                    VUE_APP_SCOPE: scope,
                    VUE_APP_CALLBACK_URL: callbackUrl,
                } = process.env;
                location.assign(
                    `${authUrl}?response_type=token&client_id=${clientId}&scope=${scope}&redirect_uri=${callbackUrl}`
                );
            },
        },
    };
</script>

<style lang="scss" scoped>
    .login {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .login__header {
        font-weight: 700;
        text-align: left;
        color: $white;
        margin: $space-100 0 $space-32;

        font-size: $font-24;
        line-height: $font-24;

        @include media($md) {
            font-size: $font-36;
            line-height: $font-36;
            letter-spacing: 0;
        }
    }

    .login__form {
        display: flex;
        align-items: center;
        flex-direction: column;

        border-radius: $border-radius-8;
        background: $marine-800;
        box-shadow: 0 0 120px rgba(36, 36, 49, 0.5);
        padding: $space-32 $space-32 $space-16;

        @include media($lg) {
            width: 520px;
        }
    }

    .form__item {
        width: 100%;
    }

    .form__item:not(:first-child) {
        margin: $space-32 0 0;
    }

    .form__button {
        max-width: 120px;
    }

    .form__label {
        margin: 0 0 $space-16;
        font-weight: normal;
        font-size: $font-18;
        line-height: $font-26;
        color: $marine-300;
    }

    .login__language {
        margin-top: 80px;
        @include media($md) {
            margin-top: 70px;
        }
    }
</style>
