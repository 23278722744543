<template>
    <mf-select
        class="language-selector"
        v-model="lang"
        :options="languageOptions"
        :show-labels="false"
        :searchable="false"
        :allow-empty="false"
        :hide-selected="true"
        langSelector
        preselectFirst
    >
        <div slot="caret">
            <mf-icon class="multiselect__select" icon-name="icon-down" />
        </div>
        <div class="language-selector__item" slot="singleLabel" slot-scope="props">
            <img class="item__image" :src="flagImage(props.option)" alt="flag" />
            <span class="item__text">{{ props.option }}</span>
        </div>
        <div class="language-selector__item" slot="option" slot-scope="props">
            <img class="item__image" :src="flagImage(props.option)" alt="flag" />
            <span class="item__text">{{ props.option }}</span>
        </div>
    </mf-select>
</template>

<script>
    export default {
        data() {
            return {
                lang: 'English',
                languageOptions: ['English', 'Swedish', 'Spain'],
            };
        },
        methods: {
            flagImage(option) {
                const flagImages = require.context('@/assets/icons/flags', false, /\.svg$/);
                const flagImage = option.toLowerCase();
                return flagImages(`./${flagImage}.svg`);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .language-selector {
        max-width: 200px;
    }

    .language-selector__item {
        display: flex;
        align-items: center;
    }

    .item__image {
        height: 24px;
    }

    .item__text {
        margin-left: $space-16;
    }
</style>
